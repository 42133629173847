import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import qs from'query-string';

import { unsubscribeNewsletter } from '@src/http/UserRepository';
import { useInput } from '@src/Helper';

// import '@components/EditProfile/_edit-profile.scss';

function UnsubscribeNewsletter({ location }) {
  const companyLogo = useStaticQuery(graphql`
    query queryUnsubNewsletterCompanyLogo {
      logo: file(relativePath: {eq: "logos/exm_logo.png"}) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  // STATES
  const unsubscribe = useInput(null);
  const message = useInput({
    title: 'Verifying...',
    body: '',
  })

  // API CALLS
  const unsubscribeEmail = ({email}) => {
    unsubscribeNewsletter(email).then(res => {
      unsubscribe.setValue(res.data.message);
      message.setValue({
        title: "Sad to lose contact with you.",
        body: "At this rate, you won’t receive any news about EXM. If you don’t want to miss out on any latest updates from EXM, make sure to subscribe to our newsletter again."
      })
    }).catch(err => {
      unsubscribe.setValue(null);
      message.setValue({
        title: "Oops...looks like there's something wrong!",
        body: "Fail to change your email. Try to re-click the button in the email we sent you or contact our support."
      })
      console.log(err)
    })
  }

  // EFFECTS
  useEffect(() => {
    const queryString = qs.parse(location.search);
    unsubscribeEmail(queryString);
  }, [])

  return (
    <div id="verification" className="section-128">
      <div className="container text-center">
        <Image className="verification-company-logo" fluid={companyLogo.logo.childImageSharp.fluid} alt="executivemafia" />
        <h1 className="title text-broccoli-green">{message.value.title}</h1>
        <p className="subcaption mt-2">{message.value.body} </p>
        <Link
          to="/dashboard"
          className="button button-mafia-primary mt-4 d-inline-block"
        >
          Go to Dashboard
        </Link>
      </div>
    </div>
  )
}

export default UnsubscribeNewsletter;